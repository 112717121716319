@import "./variable.module.scss";

*, :after, :before {
  box-sizing: border-box;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_bold.otf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_bolditalic.otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_light.otf");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_lightitalic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_medium.otf");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_mediumitalic.otf");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_xlight.otf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "gotham";
  src: url("../font/gothamssm_xlightitalic.otf");
  font-weight: 400;
  font-style: italic;
}

html, body {
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  margin: 0;
  padding: 0;
  font-family: "gotham" !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 3px !important;
  color: $black;
  background-color: $white;
  position: relative;
  min-height: 100vh;
  font-size: 100%;
  line-height: 1.1;
}

a {
  cursor: pointer;
  text-decoration: unset;
  color: inherit;
}

.container, .container-home{
  padding: 0px !important;
  max-width: unset !important;
}

.container{
  padding-bottom: 50px !important;
}

.footer{
  width: 100%;
  width: -webkit-fill-available;
}

.block {
  text-align: center;
  background-color: $light-grey;
  padding: 30px 20px 20px 20px;
  min-height: 350px;
}

*, *:before, *:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

input, textarea, button, p, span {
  font-size: inherit !important;
  font-family: inherit !important;
}